import React, { useState } from 'react';
import './App.css';
import { ReactComponent as Logo } from './logo.svg';

function App() {
  const [input, setInput] = useState('');
  const [output, setOutput] = useState(['Welcome to Trypto, the Digital Third Eye.']);

  const handleCommand = (command: string): string => {
    switch (command.toLowerCase()) {
      case 'time':
        return new Date().toLocaleTimeString();
      case 'ca':
        return '123456Contract';
      case 'what':
        return 'project descriptions';
      case 'social':
        return 'x.com/the';
      case 'hi':
        return 'hi human';
      case 'hello':
        return 'hi human';
      case 'help':
        return 'Available commands: time, ca, what, social';
      case 'exit':
        window.close();
        return 'Attempting to close the tab...';
      default:
        return `Command not recognized: ${command}`;
    }
  };

  const handleSubmit = (e: React.FormEvent): void => {
    e.preventDefault();
    if (input.trim()) {
      const result = handleCommand(input);
      setOutput([...output, `> ${input}`, result]);
      setInput('');
    }
  };

  return (
    <div className="app">
      <div className="console">
        <Logo className="logo" />
        {output.map((line, index) => (
          <p key={index} className="output-line">{line}</p>
        ))}
        <form onSubmit={handleSubmit}>
          <span className="prompt">&gt;</span>
          <input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            className="input"
            autoFocus
          />
        </form>
      </div>
    </div>
  );
}

export default App;